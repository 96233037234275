import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "guiding-guides",
      "style": {
        "position": "relative"
      }
    }}>{`Guiding Guides`}<a parentName="h1" {...{
        "href": "#guiding-guides",
        "aria-label": "guiding guides permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <p><strong parentName="p">{`Welcome to the conversation about how to create cohesive community`}</strong></p>
    <p>{`This website is built so that anyone can `}<em parentName="p">{`learn`}</em>{` from it, `}<em parentName="p">{`use`}</em>{` it, `}<em parentName="p">{`contribute`}</em>{` content
to it, and `}<em parentName="p">{`extend`}</em>{` it easily. We want the people who write captivating ideas, and the
people who code awesome tools, and the people who design our community brand to be in a
constant dialogue where ideas circulate smoothly and we all benefit from each other's
unique skills.`}</p>
    <p>{`This section has five parts to it:`}</p>
    <ol>
      <li parentName="ol">{`An introduction to `}<a parentName="li" {...{
          "href": "/guiding/writers"
        }}>{`writing`}</a>{` content for this site, which explains what mdx - or extended Markdown - is and how to make full use of it, as well as providing some explanations about how SEO, images, headers and sidebars work. `}</li>
      <li parentName="ol">{`The various `}<a parentName="li" {...{
          "href": "/guiding/guides"
        }}>{`guides for guides`}</a>{` which help those further along in their never-ending Kernel journey. These explain how to host a junto, how to run interviews for future blocks, and how to think about hosting conversations in general.`}</li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/guiding/writers/recipes"
        }}>{`Recipes`}</a>{` for writers who want quick references to all the React components we can inject into mdx files to make any content stand out.`}</li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/guiding/engineers"
        }}>{`Engineering`}</a>{` manuals for developers who want to understand how technical things like search, navigation and translations actually work on this site, so that they can fork it easily and build their own educational environments.`}</li>
      <li parentName="ol">{`Our `}<a parentName="li" {...{
          "href": "/guiding/contracts"
        }}>{`Learning Curve`}</a>{` specification which explains the smart contracts that run the continuous online learning environment Kernel is building, for those who wish to plug into them and create their own courses which benefit from this growing learning community.`}</li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      